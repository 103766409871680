import { API_TYPES, get } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import {
  type QueryFunctionContext,
  type QueryKey,
  useInfiniteQuery,
  type UseInfiniteQueryOptions,
} from "@tanstack/react-query";
import { type AxiosError } from "axios";
import { utcToZonedTime } from "date-fns-tz";
import { z } from "zod";

import { type Shift } from "../../Shift/types";

const interviewsApiUrl = `${environmentConfig.REACT_APP_BASE_API_URL}/interviews`;

export enum InterviewStatus {
  BOOKED = "Booked",
  CANCELLED = "Cancelled",
  RESCHEDULED = "Rescheduled",
}

const interviewRelationshipsSchema = z.object({
  workplace: z.object({
    data: z.object({
      type: z.literal(API_TYPES.workplace),
      id: z.string(),
    }),
  }),
  placementApplication: z.object({
    data: z.object({
      type: z.literal(API_TYPES.placementApplication),
      id: z.string(),
    }),
  }),
  placement: z.object({
    data: z.object({
      type: z.literal(API_TYPES.placement),
      id: z.string(),
    }),
  }),
  placementCandidate: z.object({
    data: z.object({
      type: z.literal(API_TYPES.placementCandidate),
      id: z.string(),
    }),
  }),
});

const interviewIncludedWorkplaceSchema = z.object({
  type: z.literal(API_TYPES.workplace),
  id: z.string(),
  attributes: z.object({
    name: z.string(),
    formattedAddress: z.string().optional(),
    distance: z.number().optional(),
    phone: z.string().optional(),
  }),
});
type InterviewIncludedWorkplace = z.infer<typeof interviewIncludedWorkplaceSchema>;

const interviewIncludedPlacementSchema = z.object({
  type: z.literal(API_TYPES.placement),
  id: z.string(),
  attributes: z.object({
    workerTypes: z.array(z.string()),
  }),
});
type InterviewIncludedPlacement = z.infer<typeof interviewIncludedPlacementSchema>;
const interviewIncludedSchema = z.array(
  z.discriminatedUnion("type", [interviewIncludedWorkplaceSchema, interviewIncludedPlacementSchema])
);

export const interviewSchema = z.object({
  id: z.string(),
  type: z.literal(API_TYPES.interview),
  attributes: z.object({
    start: z.string(),
    end: z.string(),
    status: z.nativeEnum(InterviewStatus),
  }),
  relationships: interviewRelationshipsSchema,
});

export type Interview = z.infer<typeof interviewSchema>;

export const getInterviewsResponseSchema = z.object({
  data: z.array(interviewSchema),
  included: interviewIncludedSchema.optional(),
  links: z.object({
    next: z.string().optional(),
    prev: z.string().optional(),
  }),
});

export type GetInterviewsResponse = z.infer<typeof getInterviewsResponseSchema>;

export const getInterviewsRequestSchema = z.object({
  page: z.object({
    size: z.number(),
    cursor: z.string().optional(),
  }),
  filter: z.object({
    start: z.object({
      gt: z.string(),
    }),
    end: z
      .object({
        lt: z.string(),
      })
      .optional(),
    workerId: z.string(),
    status: z.nativeEnum(InterviewStatus),
  }),
});

interface FetchInterviewsParams {
  filter: z.infer<typeof getInterviewsRequestSchema>["filter"];
}

export type GetInterviewsRequest = z.infer<typeof getInterviewsRequestSchema>;

export function useFetchPaginatedInterviews(
  params: FetchInterviewsParams,
  options: UseInfiniteQueryOptions<GetInterviewsResponse, AxiosError> = {}
) {
  const { filter } = params;

  return useInfiniteQuery({
    queryKey: [interviewsApiUrl, filter],
    queryFn: async ({
      pageParam: pageParameter = undefined,
    }: QueryFunctionContext<QueryKey, string | undefined>) => {
      const nonEmptyFilter = Object.fromEntries(
        Object.entries(filter ?? {}).filter(([_, value]) => value)
      );
      const response = await get({
        url: interviewsApiUrl,
        queryParams: {
          page: {
            size: 100,
            cursor: pageParameter,
          },
          filter: nonEmptyFilter,
        },
        responseSchema: getInterviewsResponseSchema,
        requestSchema: getInterviewsRequestSchema,
      });

      return response.data;
    },
    getNextPageParam: (lastPage) => lastPage.links.next ?? undefined,
    ...options,
  });
}

export function transformResponseIntoInterview(
  interview: z.infer<typeof interviewSchema>,
  includedMetadata: z.infer<typeof interviewIncludedSchema>,
  workerTimezone: string
) {
  const workplace = includedMetadata.find(
    (included): included is InterviewIncludedWorkplace =>
      included.type === API_TYPES.workplace &&
      included.id === interview.relationships.workplace.data.id
  );

  const placement = includedMetadata.find(
    (included): included is InterviewIncludedPlacement =>
      included.type === API_TYPES.placement &&
      included.id === interview.relationships.placement.data.id
  );

  return {
    id: interview.id,
    type: interview.type,
    ...interview.attributes,
    start: utcToZonedTime(interview.attributes.start, workerTimezone),
    end: utcToZonedTime(interview.attributes.end, workerTimezone),
    placementId: interview.relationships?.placement?.data?.id,
    placementApplicationId: interview.relationships?.placementApplication?.data?.id,
    ...(workplace && {
      workplace: {
        id: workplace.id,
        name: workplace.attributes.name,
        formattedAddress: workplace.attributes.formattedAddress,
        distance: workplace.attributes.distance,
        phone: workplace.attributes.phone,
      },
    }),
    ...(placement && {
      placement: {
        id: placement.id,
        workerTypes: placement.attributes.workerTypes,
      },
    }),
    placementCandidateId: interview.relationships?.placementCandidate?.data?.id,
  };
}

export type InterviewWithDetails = ReturnType<typeof transformResponseIntoInterview>;

export type Bookings =
  | { type: "shift"; data: Shift }
  | { type: "interview"; data: InterviewWithDetails };
