// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable max-lines */
import { formatISODateTimeZone } from "@clipboard-health/date-time";
import { isDefined } from "@clipboard-health/util-ts";
import { IonPage } from "@ionic/react";
import { Stack } from "@mui/material";
import { useSentHomeRequests } from "@src/appV2/Agents/api/useSentHomeRequests";
import { useBookedCases } from "@src/appV2/ExperimentalHomeHealth/BookedCases/api/useBookedCases";
import { BookedCasesWrapper } from "@src/appV2/ExperimentalHomeHealth/BookedCases/BookedCasesWrapper";
import { useIsHomeHealthEnabled } from "@src/appV2/ExperimentalHomeHealth/lib/useIsHomeHealthEnabled";
import { CaseStatus } from "@src/appV2/ExperimentalHomeHealth/types";
import { useFacilityVerificationPreferences } from "@src/appV2/Facilities/api/useFacilityVerificationPreferences";
import { useGetChatChannels } from "@src/appV2/Facilities/Chat/api/useGetChatChannels";
import { FacilityChatMessagesButton } from "@src/appV2/Facilities/Chat/MessagesButton";
import { PullToRefresh } from "@src/appV2/lib/PullToRefresh/PullToRefresh";
import { EnforceLocationPermissionsContainer } from "@src/appV2/LocationPermission/EnforceLocationPermissionsContainer";
import { useOpenNegotiationsCount } from "@src/appV2/Negotiations/api/useOpenNegotiationsCount";
import { NotificationCenterButton } from "@src/appV2/NotificationCenter";
import { useIsJobInterviewsEnabled } from "@src/appV2/redesign/Placements/useIsJobInterviewsEnabled";
import { useIsShiftDiscoveryEnabled } from "@src/appV2/redesign/ShiftDiscovery/useIsShiftDiscoveryEnabled";
import { useExtraWorkedTimeRequests } from "@src/appV2/Shifts/MandatoryBreakPolicy/api/useExtraWorkedTimeRequests";
import { PendingShiftInvitesCount } from "@src/appV2/Shifts/ShiftInvites/PendingShiftInvitesCount";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { addDays, addHours, compareAsc, isBefore, parseISO } from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";
import { sumBy } from "lodash";
import { useLocation } from "react-router-dom";

import { AppBarHeader, PageWithHeader } from "../../lib";
import { useWorkerUnverifiedShifts } from "../../redesign/WorkerShifts/UnverifiedShifts/useWorkerUnverifiedShifts";
import {
  type Bookings,
  InterviewStatus,
  transformResponseIntoInterview,
  useFetchPaginatedInterviews,
} from "./api/useFetchPaginatedInterviews";
import { useFutureShifts } from "./api/useFutureShifts";
import { useNextTwoDaysShifts } from "./api/useNextTwoDaysShifts";
import { EmptyMyShiftsPlaceholder } from "./components/EmptyMyShiftsPlaceholder";
import { InterviewCard } from "./components/InterviewCard/InterviewCard";
import { PendingSentHomeRequestsCard } from "./components/PendingSentHomeRequestsCard";
import { RateNegotiationsCard } from "./components/RateNegotiationsCard";
import { ShiftCard } from "./components/ShiftCard";
import { ShiftCardLoadingPlaceholder } from "./components/ShiftCardLoadingPlaceholder";
// eslint-disable-next-line import/max-dependencies
import { UnverifiedShiftsCard } from "./components/UnverifiedShiftsCard";

// eslint-disable-next-line complexity, sonarjs/cognitive-complexity
export function MyShiftsPage() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const worker = useDefinedWorker();
  const isJobInterviewsEnabled = useIsJobInterviewsEnabled();
  const {
    isLoading: isLoadingNextTwoDaysShifts,
    data: nextTwoDaysShiftsData,
    refetch: refetchNextTwoDaysShifts,
  } = useNextTwoDaysShifts(worker.tmz ?? "", { enabled: isDefined(worker.tmz) });

  const {
    isLoading: isLoadingFutureShifts,
    data: futureShiftsData,
    refetch: refetchFutureShifts,
  } = useFutureShifts(worker.tmz ?? "", { enabled: isDefined(worker.tmz) });

  const {
    isSuccess: isSuccessUnverifiedShifts,
    data: unverifiedShiftsData,
    refetch: refetchUnverifiedShifts,
  } = useWorkerUnverifiedShifts();

  const { data: extraWorkedTimeRequestsData, refetch: refetchExtraWorkedTimeRequests } =
    useExtraWorkedTimeRequests();

  const unverifiedShiftsFacilityIds = (unverifiedShiftsData?.response ?? [])
    .map((shift) => shift.facility.userId)
    .filter((facilityId) => isDefined(facilityId));

  const { data: facilityVerificationPreferencesData } = useFacilityVerificationPreferences(
    { facilityIds: unverifiedShiftsFacilityIds },
    { enabled: isSuccessUnverifiedShifts && unverifiedShiftsFacilityIds.length > 0 }
  );

  const { data: openNegotiationsCount, refetch: refetchOpenNegotiationsCount } =
    useOpenNegotiationsCount();

  const { data: sentHomeRequestsData, refetch: refetchSentHomeRequests } = useSentHomeRequests();

  const {
    isLoading: isLoadingInterviews,
    data: interviewsDataWithPages,
    refetch: refetchInterviews,
  } = useFetchPaginatedInterviews(
    {
      filter: {
        workerId: worker.userId,
        status: InterviewStatus.BOOKED,
        start: {
          gt: formatISODateTimeZone(new Date(), { timeZone: "UTC" }),
        },
        end: {
          lt: formatISODateTimeZone(addDays(new Date(), 3), { timeZone: "UTC" }),
        },
      },
    },
    { enabled: isDefined(worker.userId) && isJobInterviewsEnabled }
  );

  const {
    isLoading: isLoadingChatChannels,
    data: chatChannelsData,
    refetch: refetchChatChannels,
  } = useGetChatChannels();

  const nextTwoDaysShifts = nextTwoDaysShiftsData?.response ?? [];
  const futureShifts = futureShiftsData?.response ?? [];
  const activeNegotiationsCount = openNegotiationsCount?.totalNegotiatingShiftsCount ?? 0;
  const facilityProposalsCount = openNegotiationsCount?.negotiationsWaitingOnWorker ?? 0;
  const recentlyEndedNegotiationsCount = openNegotiationsCount?.recentlyEndedNegotiationsCount ?? 0;
  const sentHomeRequests = sentHomeRequestsData ?? [];
  const unverifiedShifts = unverifiedShiftsData?.response ?? [];
  const extraWorkedRequests = extraWorkedTimeRequestsData?.data ?? [];
  const chatChannels = chatChannelsData?.channels ?? [];

  const interviewsData = interviewsDataWithPages?.pages.flatMap((page) =>
    page.data.map((interview) =>
      transformResponseIntoInterview(interview, page.included ?? [], worker.tmz ?? "")
    )
  );

  const shifts = [...nextTwoDaysShifts, ...futureShifts].sort((a, b) => {
    return compareAsc(parseISO(a.start), parseISO(b.start));
  });

  const bookings: Bookings[] = [
    ...shifts.map((shift) => ({ type: "shift" as const, data: shift })),
    ...(interviewsData ?? []).map((interview) => ({ type: "interview" as const, data: interview })),
  ].sort((a, b) => {
    const aDate = parseISO(
      a.type === "shift"
        ? a.data.start
        : zonedTimeToUtc(a.data.start, worker.tmz ?? "").toISOString()
    );
    const bDate = parseISO(
      b.type === "shift"
        ? b.data.start
        : zonedTimeToUtc(b.data.start, worker.tmz ?? "").toISOString()
    );
    return compareAsc(aDate, bDate);
  });

  const isLoading =
    isLoadingNextTwoDaysShifts ||
    isLoadingFutureShifts ||
    isLoadingChatChannels ||
    (isLoadingInterviews && isJobInterviewsEnabled);

  const shiftsWithMissingTimesheets = unverifiedShifts.filter((shift) => {
    const hasReason = isDefined(shift.timecardNotAvailable?.reason);
    const hasFiles =
      isDefined(shift.timecard) &&
      isDefined(shift.timecard?.files) &&
      shift.timecard.files.length > 0;

    const verificationPreferences = facilityVerificationPreferencesData?.find((preferences) => {
      return isDefined(preferences.mongodbId) && preferences.mongodbId === shift.facility.userId;
    });
    const usesTimesheets = isDefined(verificationPreferences?.usesTimesheets);

    if (!isDefined(shift) || hasReason || hasFiles || !usesTimesheets) {
      return false;
    }

    const shiftVerificationTimeout = shift.businessrules?.value;
    return (
      !isDefined(shiftVerificationTimeout) ||
      isBefore(
        new Date(),
        addHours(parseISO(shift.end), Number.parseInt(shiftVerificationTimeout, 10))
      )
    );
  });

  const isHomeHealthEnabled = useIsHomeHealthEnabled();

  const { refetch: refetchHomeHealthOpenCases } = useBookedCases(
    {
      filter: { status: CaseStatus.OPEN },
    },
    { enabled: false }
  );
  const isShiftDiscoveryEnabled = useIsShiftDiscoveryEnabled();
  return (
    <IonPage>
      <PageWithHeader
        containerMaxWidth="md"
        appBarHeader={
          <AppBarHeader
            title={isJobInterviewsEnabled ? "My Bookings" : "My Shifts"}
            leftCta={<NotificationCenterButton />}
            rightCta={
              isShiftDiscoveryEnabled ? null : <FacilityChatMessagesButton agentId={worker._id} />
            }
          />
        }
      >
        <EnforceLocationPermissionsContainer useLegacyDialog />
        <PullToRefresh
          onRefresh={async () => {
            await Promise.all([
              refetchNextTwoDaysShifts(),
              refetchFutureShifts(),
              refetchUnverifiedShifts(),
              refetchExtraWorkedTimeRequests(),
              refetchOpenNegotiationsCount(),
              refetchSentHomeRequests(),
              refetchChatChannels(),
              isHomeHealthEnabled ? refetchHomeHealthOpenCases() : Promise.resolve(),
              isJobInterviewsEnabled ? refetchInterviews() : Promise.resolve(),
            ]);
          }}
        />
        <Stack spacing={2}>
          <RateNegotiationsCard
            activeNegotiationsCount={activeNegotiationsCount}
            facilityProposalsCount={facilityProposalsCount}
            recentlyEndedNegotiationsCount={recentlyEndedNegotiationsCount}
            deepLinkNegotiationId={searchParams.get("negotiationId") ?? undefined}
          />
          {sentHomeRequests.length > 0 ? (
            <PendingSentHomeRequestsCard
              agentId={worker._id}
              pendingSentHomeRequestsCount={sentHomeRequests.length}
              totalEarnings={sumBy(sentHomeRequests, (request) => request.earnings)}
            />
          ) : null}
          {unverifiedShifts.length > 0 || extraWorkedRequests.length > 0 ? (
            <UnverifiedShiftsCard
              agentId={worker._id}
              unverifiedShiftsCount={unverifiedShifts.length}
              missingTimesheetsCount={shiftsWithMissingTimesheets.length}
              extraWorkedTimeRequestsCount={extraWorkedRequests.length}
            />
          ) : null}
          <PendingShiftInvitesCount />
          {isLoading && <ShiftCardLoadingPlaceholder />}
          {!isLoading && bookings.length === 0 && <EmptyMyShiftsPlaceholder />}
          {bookings
            .filter((booking) => {
              if (booking.type === "shift") {
                return (
                  !isDefined(booking.data._id) ||
                  !sentHomeRequests.some((request) => request.shift._id === booking.data._id)
                );
              }

              return true;
            })
            .map((booking) => {
              switch (booking.type) {
                case "shift": {
                  return (
                    <ShiftCard
                      key={booking.data._id}
                      worker={worker}
                      shift={booking.data}
                      chatChannels={chatChannels}
                    />
                  );
                }

                case "interview": {
                  return (
                    <InterviewCard
                      key={booking.data.id}
                      interview={booking.data}
                      chatChannels={chatChannels}
                    />
                  );
                }

                default: {
                  return null;
                }
              }
            })}
          {isHomeHealthEnabled && <BookedCasesWrapper />}
        </Stack>
      </PageWithHeader>
    </IonPage>
  );
}
